import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import LeadParagraph from '../../../components/LeadParagraph';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "data-formats",
      "style": {
        "position": "relative"
      }
    }}>{`Data formats`}<a parentName="h1" {...{
        "href": "#data-formats",
        "aria-label": "data formats permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
  Helsinki Design System suggests formats for commonly presented data to keep Helsinki services more consistent.
    </LeadParagraph>
    <h2 {...{
      "id": "date-and-time",
      "style": {
        "position": "relative"
      }
    }}>{`Date and time`}<a parentName="h2" {...{
        "href": "#date-and-time",
        "aria-label": "date and time permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "format",
      "style": {
        "position": "relative"
      }
    }}>{`Format`}<a parentName="h3" {...{
        "href": "#format",
        "aria-label": "format permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><strong parentName="p">{`Helsinki services are recommended to follow the Finnish date and time locale - even if the user is using some other language.`}</strong>{` Follow these guidelines when presenting date and time in your services.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Format`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Notes`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time (forms, schedules, etc.)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`15:00, 02:00`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When presenting times in forms and schedules, use leading zeros and a colon separator. A 24-hour clock is always used.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time (in text)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`15.00, 2.00`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When presenting times in the written text, use a single dot instead of a colon separator. Leading zeros can be opted out as well. A 24-hour clock is always used.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Timer (forms)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`05:00`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When presenting timer in forms that counts down expiration. The time format is mm:ss.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Day, month, and year`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24.6.2020`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date format is D.M.YYYY even if the user's locale is something else than Finnish. Leading zeros are not used for dates.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Day, month, and year (longer)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24. June 2020, 24 June 2020`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Using the written format of the month makes the date easier to understand for different locales. Note that technically English translation omits the dot after day number.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Today, tomorrow, yesterday`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Today, 12:30`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If talking about today, tomorrow, or yesterday, the written format is used instead of date.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 1:Recommended time formats]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "ranges",
      "style": {
        "position": "relative"
      }
    }}>{`Ranges`}<a parentName="h3" {...{
        "href": "#ranges",
        "aria-label": "ranges permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`When presenting time or date ranges, consider making information simpler by leaving redundant month and year information out. `}<strong parentName="p">{`Note that when presenting ranges, en dash (–) should be used instead of a standard hyphen (-).`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Format`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Notes`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time range (forms, schedules, etc.)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12:30–16:30`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When presenting times in forms and schedules, use leading zeros and a colon separator.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time range (in text)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12.30–16.30`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When presenting times in the written text, use a single dot instead of a colon separator.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Date range`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2.–15. January 2020 / 2.1.–5.2.2020`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Year and/or month can be only written once if both dates share the same year/month.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 2:Recommended range formats]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "time-zones",
      "style": {
        "position": "relative"
      }
    }}>{`Time zones`}<a parentName="h3" {...{
        "href": "#time-zones",
        "aria-label": "time zones permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><strong parentName="p">{`Automatically use the user's timezone if possible.`}</strong>{` If not available, default to Finnish time and present the time zone as follows:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Format`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Notes`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time zone`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`UTC+2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Leading or trailing zeroes are not recommended to be used for timezones.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`15:00 UTC+2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24. June 2020, 15:30 UTC+2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 3:Recommended time zone formats]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      